import { energySliderValueIsDark } from "~/context/global";
import { unreachableValue } from "~/utils/unreachable";

import { COLORS } from "../../tailwind.config";

export { COLORS };

export type RgbColor = [number, number, number];

export type AdjustableColorRange = {
	type: "range";
	min: RgbColor;
	max: RgbColor;
};

export type AdjustableColorBinary = {
	type: "binary";
	dark: RgbColor;
	light: RgbColor;
};

export type AdjustableColor = AdjustableColorRange | AdjustableColorBinary;

export function adjustColor(light: number, color: AdjustableColor): RgbColor {
	switch (color.type) {
		case "range": {
			const r =
				Math.round(((color.max[0] - color.min[0]) * light) / 100) +
				color.min[0];
			const g =
				Math.round(((color.max[1] - color.min[1]) * light) / 100) +
				color.min[1];
			const b =
				Math.round(((color.max[2] - color.min[2]) * light) / 100) +
				color.min[2];
			return [r, g, b];
		}

		case "binary": {
			const isDark = energySliderValueIsDark(light);
			return isDark ? color.dark : color.light;
		}

		default:
			unreachableValue(color);
	}
}

export function getAdjustedColors(
	light: number,
): Record<keyof typeof COLORS, RgbColor> {
	const entries = Object.entries(COLORS).map(
		([key, color]) => [key, adjustColor(light, color)] as const,
	);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return Object.fromEntries(entries) as any;
}

export function rgbColorToStyleColor(color: RgbColor): string {
	return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}

export function rgbColorToTwVar(color: RgbColor): string {
	return `${color[0]} ${color[1]} ${color[2]}`;
}
