export class AbortError extends DOMException {
	constructor(message: string = "Aborted") {
		super(message, "AbortError");
	}
}

export type DelayOptions = {
	signal?: AbortSignal;
};

export function delay(
	delayMs: number,
	{ signal }: DelayOptions = {},
): Promise<void> {
	return new Promise((onOk, onErr) => {
		if (signal?.aborted) {
			onErr(new AbortError("Delay aborted"));
			return;
		}

		const handle = setTimeout(onOk, delayMs);
		signal?.addEventListener("abort", () => {
			clearTimeout(handle);
			onErr(new AbortError("Delay aborted"));
		});
	});
}

export function isAbortError(
	val: unknown,
): val is DOMException & { name: "AbortError" } {
	return val instanceof DOMException && val.name === "AbortError";
}

export function spawnDetached(
	f: (signal: AbortSignal) => Promise<void>,
): () => void {
	const abortCtl = new AbortController();
	f(abortCtl.signal).catch((err) => {
		// ignore abort exceptions
		if (!isAbortError(err)) {
			console.error(err);
		}
	});
	return () => {
		abortCtl.abort();
	};
}
