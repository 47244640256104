import { useIntl } from "react-intl";

export const DEFAULT_LANGUAGE = "de";

export const SUPPORTED_LANGUAGES = [DEFAULT_LANGUAGE, "en"] as const;

type Language = (typeof SUPPORTED_LANGUAGES)[number];

export default function useLanguage() {
	return useIntl().locale as Language;
}
