import { useEffect, useCallback, type ComponentPropsWithoutRef } from "react";
import { z } from "zod";

import { CONSENT_NAMES, useGlobal } from "~/context/global";
import { spawnDetached } from "~/utils/async";
import { cn } from "~/utils/cn";

/** @see https://klaro.org/docs/api/js_api */
function importKlaro() {
	// @ts-expect-error There are no type defs for klaro
	return import("klaro/dist/klaro-no-css");
}

export default function ConsentModal() {
	const { setGlobal } = useGlobal();

	useEffect(
		() =>
			spawnDetached(async (abortSignal) => {
				const klaro = await importKlaro();
				const manager = klaro.getManager(KLARO_CONFIG);

				function updateConsents() {
					const data = managerDataSchema.safeParse(manager);
					if (data.success) {
						setGlobal((g) => {
							for (const key of CONSENT_NAMES) {
								g.consents[key] =
									data.data.consents[key] ?? false;
							}
						});
					}
				}

				const watcher = { update: updateConsents };
				abortSignal.addEventListener("abort", () => {
					manager.unwatch(watcher);
				});
				manager.watch(watcher);
				updateConsents();
				klaro.render(KLARO_CONFIG);
			}),
		[setGlobal],
	);

	return <div id={KLARO_ELEMENT_ID} />;
}

export type ShowConsentModalButtonProps = Omit<
	ComponentPropsWithoutRef<"button">,
	"onClick"
>;

export function ShowConsentModalButton({
	className,
	...rest
}: ShowConsentModalButtonProps) {
	const handleClick = useShowConsentModal();
	return (
		<button
			className={cn("show-consent-modal-button", className)}
			onClick={handleClick}
			{...rest}
		/>
	);
}

export function useShowConsentModal(): () => void {
	return useCallback(() => {
		spawnDetached(async () => {
			const klaro = await importKlaro();

			// force show
			klaro.show(KLARO_CONFIG);
		});
	}, []);
}

const managerDataSchema = z.object({
	consents: z.record(z.string(), z.boolean()),
});

const KLARO_ELEMENT_ID = "klaro-consent-CF5BD1C8-4EA7-45EE-8BF2-13599C4A2E73";

const KLARO_CONFIG = {
	elementID: KLARO_ELEMENT_ID,

	// You can override CSS style variables here. For IE11, Klaro will
	// dynamically inject the variables into the CSS. If you still consider
	// supporting IE9-10 (which you probably shouldn't) you need to use Klaro
	// with an external stylesheet as the dynamic replacement won't work there.
	//styling: {
	//	theme: ['light', 'top', 'wide'],
	//},

	// Setting this to true will keep Klaro from automatically loading itself
	// when the page is being loaded.
	noAutoLoad: false,

	// Setting this to true will render the descriptions of the consent
	// modal and consent notice are HTML. Use with care.
	htmlTexts: true,

	// Setting 'embedded' to true will render the Klaro modal and notice without
	// the modal background, allowing you to e.g. embed them into a specific element
	// of your website, such as your privacy notice.
	embedded: false,

	// You can group services by their purpose in the modal. This is advisable
	// if you have a large number of services. Users can then enable or disable
	// entire groups of services instead of having to enable or disable every service.
	groupByPurpose: true,

	// How Klaro should store the user's preferences. It can be either 'cookie'
	// (the default) or 'localStorage'.
	storageMethod: "cookie",

	// You can customize the name of the cookie that Klaro uses for storing
	// user consent decisions. If undefined, Klaro will use 'klaro'.
	cookieName: "klaro-cmp",

	// You can also set a custom expiration time for the Klaro cookie.
	// By default, it will expire after 120 days.
	cookieExpiresAfterDays: 120,

	// You can change to cookie domain for the consent manager itself.
	// Use this if you want to get consent once for multiple matching domains.
	// If undefined, Klaro will use the current domain.
	//cookieDomain: '.github.com',

	// You can change to cookie path for the consent manager itself.
	// Use this to restrict the cookie visibility to a specific path.
	// If undefined, Klaro will use '/' as cookie path.
	//cookiePath: '/',

	// Defines the default state for services (true=enabled by default).
	default: false,

	// If "mustConsent" is set to true, Klaro will directly display the consent
	// manager modal and not allow the user to close it before having actively
	// consented or declines the use of third-party services.
	mustConsent: false,

	// Show "accept all" to accept all services instead of "ok" that only accepts
	// required and "default: true" services
	acceptAll: true,

	// replace "decline" with cookie manager modal
	hideDeclineAll: false,

	// hide "learnMore" link
	hideLearnMore: false,

	// show cookie notice as modal
	noticeAsModal: false,

	// You can also remove the 'Realized with Klaro!' text in the consent modal.
	// Please don't do this! We provide Klaro as a free open source tool.
	// Placing a link to our website helps us spread the word about it,
	// which ultimately enables us to make Klaro! better for everyone.
	// So please be fair and keep the link enabled. Thanks :)
	disablePoweredBy: true,

	// you can specify an additional class (or classes) that will be added to the Klaro `div`
	// additionalClass: "phaeno-cmp",

	// You can define the UI language directly here. If undefined, Klaro will
	// use the value given in the global "lang" variable. If that does
	// not exist, it will use the value given in the "lang" attribute of your
	// HTML tag. If that also doesn't exist, it will use 'en'.
	//lang: 'en',

	// You can overwrite existing translations and add translations for your
	// service descriptions and purposes. See `src/translations/` for a full
	// list of translations that can be overwritten:
	// https://github.com/KIProtect/klaro/tree/master/src/translations

	// Example config that shows how to overwrite translations:
	// https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js

	// This is a list of third-party services that Klaro will manage for you.
	services: [
		// The services will appear in the modal in the same order as defined here.
		{
			name: "klaro",
			purposes: ["functional"],
			default: true,
			required: true,
		},
		//{
		//	name: 'login',
		//	purposes: ['essential'],
		//	default: true,
		//	required: true,
		//},
		{
			name: "vimeo",
			purposes: ["features"],
			cookies: [
				// TODO
			],
		},
		{
			name: "podigee",
			purposes: ["features"],
			cookies: [
				// TODO
			],
		},
		{
			name: "youtube",
			purposes: ["features"],
			cookies: [
				// TODO
			],
		},
		// {
		// 	name: "googleAnalytics",
		// 	purposes: ["analytics"],
		// 	cookies: [
		// 		[/^_ga$/, "/", ".phaeno.de"],
		// 		[/^_gaexp$/, "/", ".phaeno.de"],
		// 		[/^_gat$/, "/", ".phaeno.de"],
		// 		[/^_gid$/, "/", ".phaeno.de"],
		// 		[/^_ga_/, "/", ".phaeno.de"],
		// 		[/^_gat_/, "/", ".phaeno.de"],
		// 		[/^_gac_/, "/", ".phaeno.de"],
		// 		/^Lsid$/,
		// 		/^AMP_TOKEN$/,
		// 		/^__utma$/,
		// 		/^__utmb$/,
		// 		/^__utmc$/,
		// 		/^__utmd$/,
		// 		/^__utmt$/,
		// 		/^__utmv$/,
		// 		/^__utmz$/,
		// 	],
		// 	// If "onlyOnce" is set to true, the service will only be executed
		// 	// once regardless how often the user toggles it on and off.
		// 	onlyOnce: true,
		// },
		{
			// Each service should have a unique (and short) name.
			name: "matomo",

			// If "default" is set to true, the service will be enabled by default
			// Overwrites global "default" setting.
			// We recommend leaving this to "false" for services that collect
			// personal information.
			default: true,

			// The title of you service as listed in the consent modal.
			title: "Matomo/Piwik",

			// The purpose(s) of this service. Will be listed on the consent notice.
			// Do not forget to add translations for all purposes you list here.
			purposes: ["analytics"],

			// A list of regex expressions or strings giving the names of
			// cookies set by this service. If the user withdraws consent for a
			// given service, Klaro will then automatically delete all matching
			// cookies.
			cookies: [
				// you can also explicitly provide a path and a domain for
				// a given cookie. This is necessary if you have services that
				// set cookies for a path that is not "/" or a domain that
				// is not the current domain. If you do not set these values
				// properly, the cookie can't be deleted by Klaro
				// (there is no way to access the path or domain of a cookie in JS)
				// Notice that it is not possible to delete cookies that were set
				// on a third-party domain! See the note at mdn:
				// https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#new-cookie_domain
				[/^_pk_.*$/, "/", "klaro.kiprotect.com"], //for the production version
				[/^_pk_.*$/, "/", "localhost"], //for the local version
				"piwik_ignore",
			],

			// An optional callback function that will be called each time
			// the consent state for the service changes (true=consented). Passes
			// the `service` config as the second parameter as well.
			/*
			callback: function(consent, service) {
				// This is an example callback function.
				console.log(
					'User consent for service ' + service.name + ': consent=' + consent
				);
				// To be used in conjunction with Matomo 'requireCookieConsent' Feature, Matomo 3.14.0 or newer
				// For further Information see https://matomo.org/faq/new-to-piwik/how-can-i-still-track-a-visitor-without-cookies-even-if-they-decline-the-cookie-consent/
				//if(consent==true){
				//	_paq.push(['rememberCookieConsentGiven']);
				//} else {
				//	_paq.push(['forgetCookieConsentGiven']);
				//}
			},
			*/
			// If "required" is set to true, Klaro will not allow this service to
			// be disabled by the user.
			required: false,

			// If "optOut" is set to true, Klaro will load this service even before
			// the user gave explicit consent.
			// We recommend always leaving this "false".
			optOut: false,

			// If "onlyOnce" is set to true, the service will only be executed
			// once regardless how often the user toggles it on and off.
			onlyOnce: true,
		},
	],

	translations: {
		zz: {
			privacyPolicyUrl: "/datenschutzerklaerung/",
			googleAnalytics: {
				title: "Google Analytics",
			},
			vimeo: {
				title: "Vimeo",
			},
			youtube: {
				title: "Yotube",
			},
			podigee: {
				title: "Podigee",
			},
			purposes: {
				functional: {},
				features: {},
				analytics: {},
			},
		},
		de: {
			// ...de,
			ok: "Alle Cookies akzeptieren",
			decline: "Ablehnen",
			privacyPolicyUrl: "/datenschutz/",
			privacyPolicy: {
				name: "Datenschutzerklärung",
				text: "Um mehr zu erfahren, lesen Sie bitte unsere {privacyPolicy}.",
			},
			consentModal: {
				// ...de.consentModal,
				title: "Ihre Einstellungen",
				description:
					"Hier können Sie die Dienste, die wir auf dieser Website nutzen, anpassen. Ihre Einstellungen können Sie jederzeit ändern.",
			},
			consentNotice: {
				// ...de.consentNotice,
				// TODO
				description: `<h3>Cookie-Einstellungen</h3><p>
				Wir nutzen Cookies von Youtube, Vimeo, Podigee, Matomo und für unsere Cookie-Einstellungen,
				um Ihnen die bestmögliche Nutzung unserer Website zu ermöglichen. Ihre Einstellungen können Sie jederzeit
				ändern. Um mehr zu erfahren, lesen Sie bitte unsere
				<a href="/datenschutzerklaerung/">Datenschutzerklärung</a>.</p>`,
				learnMore: "Nur notwendige Cookies akzeptieren",
			},
			googleAnalytics: {
				description:
					"Google Analytics ist eine Web-Analyse-Lösung, die von Google angeboten wird.",
			},
			vimeo: {
				description: "Vimeo ist ein Video-Player-Dienst.",
			},
			youtube: {
				description: "Youtube ist ein Video-Player-Dienst.",
			},
			podigee: {
				description: "Podigee ist ein Podcast-Dienst.",
			},
			klaro: {
				title: "Datenschutz-Einstellungen",
				description: "Speichert Ihre Datenschutz-Einstellungen.",
			},
			purposes: {
				functional: {
					title: "Technisch notwendig",
					description:
						"Diese Technologien sind erforderlich, um die Grundfunktionalität der Website zu nutzen.",
				},
				features: {
					title: "Funktionen",
					description:
						"Diese Technologien ermöglichen es uns, das Nutzererlebnis zu verbessern.",
				},
				analytics: {
					title: "Statistik",
					description:
						"Diese Technologien ermöglichen es uns, die Nutzung der Website zu analysieren, um die Leistung zu messen und zu verbessern.",
				},
			},
			service: {
				// ...de.service,
				disableAll: {
					title: "Alle Dienste aktivieren  oder bis auf die technisch notwendigen Dienste deaktivieren",
					description: "&nbsp;", //'Mit diesem Schalter können Sie alle Dienste aktivieren oder bis auf die technisch notwendigen Dienste deaktivieren.',
				},
			},
		},
		en: {
			// ...en,
			ok: "Accept all cookies", // 'Use only necessary cookies',
			decline: "Decline",
			consentModal: {
				// ...en.consentModal,
				title: "Your settings",
				description:
					"Here you can customise the services we use on this website. You can change your settings at any time.",
			},
			consentNotice: {
				// ...en.consentNotice,
				description: `<h3>Cookie settings</h3><p>We use cookies
				(Youtube, Vimeo, Podigee, Matomo and for our Cookie settings) to give you
				the best possible use of our website. You can change your settings at any time.
				You can find out more about this in our
				<a href="/en/privacy-policy/">Privacy Policy statement</a>.</p>`,
				learnMore: "Accept only necessary cookies",
			},
			contextualConsent: {
				acceptAlways: "Always",
				acceptOnce: "Yes",
				description:
					"Do you want to load external content provided by {title}?",
			},
			privacyPolicyUrl: "/en/privacy-policy/",
			privacyPolicy: {
				name: "Privacy Policy statement",
				text: "You can find out more about this in our {privacyPolicy}.",
			},
			googleAnalytics: {
				description:
					"Google Analytics is a web analytics solution offered by Google.",
			},
			vimeo: {
				description: "Vimeo is a video player service.",
			},
			youtube: {
				description: "Youtube is a video player service.",
			},
			podigee: {
				description: "Podigee is a podcast service.",
			},
			klaro: {
				title: "Privacy settings",
				description: "Stores your privacy settings.",
			},
			purposes: {
				functional: {
					title: "Technically necessary",
					description:
						"These technologies are necessary to enable the basic functions of the website.",
				},
				features: {
					title: "Functions",
					description:
						"These technologies enable us to improve the experience for users of the website.",
				},
				analytics: {
					title: "Statistics",
					description:
						"These technologies allow us to analyse the use of the website in order to measure and improve performance.",
				},
			},
			service: {
				// ...en.service,
				disableAll: {
					title: "Enable or disable all services (except technical services)",
					description: "&nbsp;", //'This switch allows you to enable or disable all services (except technical services).',
				},
			},
		},
	},
};
