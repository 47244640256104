import makeChangeableContext from "~/utils/makeChangeableContext";

export type GlobalPropValue = {
	/** The real value is available on the *second* render, on the *first* render it's always `false` */
	isMobile: boolean;

	isMobileReal: null | boolean;

	/** Render the site containing only the site content, no script or style tags, ...
	 * Used for the Solr crawler. */
	renderStrippedDownSite: boolean;
};

export const CONSENT_NAMES = ["vimeo", "youtube", "podigee", "matomo"] as const;
export type ConsentName = (typeof CONSENT_NAMES)[number];

export type GlobalContextValue = {
	/** `0` = "dark mode", `100` = "light mode" */
	energySliderValue: number;

	/** Sets ".mobile-menu-active" on body */
	isMobileNavOpen: boolean;

	/** Sets ".calendar-active" on body */
	isCalendarOpen: boolean;

	/** Sets ".transition" on body */
	isInTransition: boolean;

	/** Sets ".cinemode" on body */
	isInCinemode: boolean;

	/** Sets ".extend" on body */
	isExtend: boolean;

	/** Sets ".page-loaded" on body */
	isPageLoaded: boolean;

	colorOverride: null | "friction";

	/** Which toolbar overlay is open */
	openToolbarOverlay: null | "tools" | "search";

	consents: Record<ConsentName, boolean>;
};

const {
	Context,
	Provider,
	useValue: useGlobal,
} = makeChangeableContext<GlobalPropValue, GlobalContextValue, "global">(
	"global",
	{
		isMobile: false,
		isMobileReal: null,
		renderStrippedDownSite: false,
	},
	{
		energySliderValue: 100,
		isMobileNavOpen: false,
		isCalendarOpen: false,
		isInTransition: false,
		isInCinemode: false,
		isExtend: false,
		isPageLoaded: false,
		colorOverride: null,
		openToolbarOverlay: null,
		consents: {
			vimeo: false,
			podigee: false,
			matomo: false,
			youtube: false,
		},
	},
);

export { Context, Provider, useGlobal };

export function energySliderValueIsDark(energySliderValue: number): boolean {
	return energySliderValue < 50;
}

export function useIsDark(): boolean {
	const { energySliderValue } = useGlobal();
	return energySliderValueIsDark(energySliderValue);
}
