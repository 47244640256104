import { useRouteLoaderData } from "@remix-run/react";

import { type loader } from "~/routes/$";

export function usePage() {
	const splat = useRouteLoaderData<typeof loader>("routes/$");
	const index = useRouteLoaderData<typeof loader>("routes/_index");
	return splat ?? index ?? null;
}

export default function usePageData() {
	const page = usePage();
	return page?.pageData ?? null;
}

export function usePageVimeoVideoData() {
	const page = usePage();
	return page?.vimeoVideoData ?? null;
}

export function useEventsData() {
	const page = usePage();
	return page?.eventsData ?? null;
}
