import type { Context, ReactNode } from "react";
import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";
import { type Immutable, produce } from "immer";

import { useStabilizeObject } from "~/hooks/useStabilize";

export type ChangeableContextValue<P, T, N extends string> = Immutable<P> &
	Immutable<T> & {
		[K in `set${Capitalize<N>}`]: (cb: (value: T) => void) => void;
	};

export type ProviderProps<P, T> = React.PropsWithChildren<
	Partial<P> & {
		initialContextValue?: Partial<T>;
	}
>;

export type ChangeableContext<P, T, N extends string> = {
	Context: Context<ChangeableContextValue<P, T, N>>;
	Provider(props: ProviderProps<P, T>): ReactNode;
	useValue(): ChangeableContextValue<P, T, N>;
};

export default function makeChangeableContext<P, T, N extends string>(
	name: N,
	defaultPropsValue: Immutable<P>,
	defaultContextValue: Immutable<T>,
): ChangeableContext<P, T, N> {
	const firstUcName = `${name.charAt(0).toUpperCase()}${name.slice(
		1,
	)}` as Capitalize<N>;
	const setName = `set${firstUcName}` as const;

	const Context = createContext<ChangeableContextValue<P, T, N>>({
		...defaultPropsValue,
		...defaultContextValue,
		...Provider,
		[setName]() {},
	} as ChangeableContextValue<P, T, N>);
	Context.displayName = `Context${firstUcName}`;

	function useValue(): ChangeableContextValue<P, T, N> {
		return useContext(Context);
	}

	function Provider({
		children,
		initialContextValue,
		...propValues
	}: ProviderProps<P, T>): ReactNode {
		const [value, setValue] = useState(() => ({
			...defaultContextValue,
			...initialContextValue,
		}));

		const handleSet = useCallback(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(cb: (value: T) => void) => setValue(produce(cb) as any),
			[],
		);
		const propValuesStable = useStabilizeObject(propValues);
		const ctxValue = useMemo(
			() =>
				({
					...value,
					...defaultPropsValue,
					...propValuesStable,
					[setName]: handleSet,
				}) as ChangeableContextValue<P, T, N>,
			[value, propValuesStable, handleSet],
		);
		return <Context.Provider value={ctxValue}>{children}</Context.Provider>;
	}
	Provider.displayName = `ContextProvider${firstUcName}`;

	return {
		Context,
		Provider,
		useValue,
	};
}
