import { useRouteLoaderData } from "@remix-run/react";

import type { loader } from "~/root";
import type { InitialData } from "~/modules/typo3/schema";
import type { LoginStatus } from "~/modules/typo3/login";

type RootData = {
	data: InitialData;
	loginStatus: Promise<null | LoginStatus>;
	isCrawler: boolean;
};

export default function useRootData() {
	return useRouteLoaderData<typeof loader>("root") as RootData;
}
