import type { Config } from "tailwindcss";
import type { ThemeConfig } from "tailwindcss/types/config";

import type { AdjustableColor } from "~/utils/colors";

const MAIN_BG_COLOR: AdjustableColor = {
	type: "range",
	max: [255, 255, 255],
	min: [0, 0, 0],
};

export const COLORS: Record<string, AdjustableColor> = {
	"main-font": { type: "binary", dark: [255, 255, 255], light: [0, 0, 0] },

	// light: {
	// 	type: "hsl",
	// 	max: 100,
	// 	min: 0,
	// }, // hsl value
	"main-bg": MAIN_BG_COLOR,
	"footer-bg": MAIN_BG_COLOR,
	"cal-bg": { type: "range", max: [255, 255, 140], min: [175, 115, 0] },
	// "tile-bg": { type: "range", max: [235, 235, 235], min: [60, 60, 60] },
	"nav-bg-kunst": { type: "range", max: [237, 255, 220], min: [33, 66, 0] },
	"nav-bg-design": { type: "range", max: [215, 255, 245], min: [0, 66, 50] },
	"nav-bg-studium": { type: "range", max: [218, 237, 255], min: [0, 34, 66] },
	"nav-bg-werkstatten": {
		type: "range",
		max: [236, 227, 255],
		min: [21, 0, 66],
	},
	"nav-bg-einrichtungen": {
		type: "range",
		max: [255, 232, 251],
		min: [66, 0, 54],
	},
	"nav-bg-personen": {
		type: "range",
		max: [255, 235, 225],
		min: [66, 22, 0],
	},
	"nav-bg-institution": {
		type: "range",
		max: [255, 240, 215],
		min: [66, 41, 0],
	},
	"grey-a": { type: "range", max: [235, 235, 235], min: [60, 60, 60] }, // formerly known as 'tile-bg'
	"grey-b": { type: "range", max: [210, 210, 210], min: [90, 90, 90] },
	"grey-c": { type: "range", max: [185, 185, 185], min: [120, 120, 120] },
};

const colorsFromVars: ThemeConfig["colors"] = Object.fromEntries(
	Object.keys(COLORS).map(
		(name) =>
			[name, `rgb(var(--tw-color-${name}) / <alpha-value>)`] as const,
	),
);

export default {
	corePlugins: {
		preflight: false,
	},
	content: ["./app/**/*.{js,jsx,ts,tsx}"],
	blocklist: [
		"container", // container class is being used a bunch in the k&r css
	],
	theme: {
		extend: {
			colors: {
				link: "#6F4FFF",
				...colorsFromVars,
			},
			spacing: {
				"gap-xs": "0.125rem",
				"gap-s": "0.25rem",
				"gap-m": ".5rem",
				"gap-l": "1rem",
				"gap-xl": "1.5rem",
				"gap-xxl": "3rem",
			},
		},
	},
	plugins: [],
} satisfies Config;
