import { useRef } from "react";

export function shallowObjectEq<T extends object>(a: T, b: T): boolean {
	// https://stackoverflow.com/a/52323412

	const keysA = Object.keys(a);
	if (keysA.length !== Object.keys(b).length) {
		return false;
	}

	return keysA.every(
		(key) =>
			Object.hasOwnProperty.call(b, key) &&
			// @ts-expect-error asdf
			a[key] === b[key],
	);
}

export function useStabilizeObject<T extends object>(value: T): T {
	const ref = useRef<T>(value);

	if (ref.current !== value && !shallowObjectEq(ref.current, value)) {
		ref.current = value;
	}

	return ref.current;
}
